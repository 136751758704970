import React, { useState, useEffect } from 'react';
import {
    useParams, useNavigate
} from "react-router-dom";
import { parseRequest } from '../../tools/request';

///ZGVudGFscGx1c3wxfDF8bg==/cs_test_fFx6VvqBxaftRJJlxNMF2dCQipzHAQDRcRFuR5T6RRSAdZZJnJRQAJC1

export default function App() {
    let { request, sessionID } = useParams();
    const [practice, setPractice] = useState({});
    let navigate = useNavigate();

    useEffect(() => {
        //console.log(request);
        //console.log(sessionID);

        async function fetchData() {
            let p = JSON.parse(localStorage.getItem('practice'));

            setPractice(p);
            localStorage.clear();
        }

        fetchData();
    }, []);

    return (

        <div className="welcomePage" style={{ paddingLeft: '6px', paddingRight: '6px' }}>
            <div className="formHeader"><h1>{practice.practice_name}</h1>
                {practice.practice_contact}</div><br />
            <p>Thank You. We'll see you soon.</p>
        </div>

    );
}
