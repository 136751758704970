import React, { useState, useEffect } from 'react';
import {
    useParams, useNavigate
} from "react-router-dom";
import { parseRequest } from '../../tools/request';

///ZGVudGFscGx1c3wxfDF8bg==/cs_test_fFx6VvqBxaftRJJlxNMF2dCQipzHAQDRcRFuR5T6RRSAdZZJnJRQAJC1

export default function App() {
    let { request, sessionID } = useParams();
    const [practice, setPractice] = useState({});
    let navigate = useNavigate();

    useEffect(() => {
        //console.log(request);
        //console.log(sessionID);

        async function fetchData() {
            let p = JSON.parse(localStorage.getItem('practice'));
            //console.log(p);
            if (!p)
                navigate("/");

            setPractice(p);

            // if we have a sessionID then we need to log that a payment was completed
            if (sessionID) {

                let sp = '';
                if (process.env.NODE_ENV === "development")
                    sp = process.env.REACT_APP_SP;
                else
                    sp = window.location.origin;

                let params = await parseRequest(request, sp);

                const requestOptions = {
                    method: 'POST',
                    mode: "cors",
                    body: JSON.stringify({
                        action: 'save_payment', cust_id: params.cust_id, practice_id: params.practice_id,
                        patient_id: params.patient_id, session_id: sessionID
                    })
                };

                //console.log(requestOptions);

                fetch(sp + '/api/form_answers.php', requestOptions)
                    .then(async response => {

                        const data = await response.json();

                        //console.log(data);

                        // check for error response
                        if (response.ok === false) {
                            // get error message from body or default to response status
                            const error = (data && data.message) || response.status;
                            return Promise.reject(error);
                        }
                        else {
                            //navigate("/thankyou/"+request);
                        }
                    })
                    .catch(error => {
                        //setFormSaveError(true);
                        console.error('There was an error!', error);
                    });

                navigate("/thankyou/" + request);

            }
            else {
                localStorage.clear();
            }
        }

        fetchData();

        /*if (localStorage.getItem('practice'))
          setPractice(JSON.parse(localStorage.getItem('practice')));
        else
          navigate("/");
    
        localStorage.clear();*/
    }, [request, navigate, sessionID]);

    return (

        <div className="welcomePage" style={{ paddingLeft: '6px', paddingRight: '6px' }}>
            <div className="formHeader"><h1>{practice.practice_name}</h1>
                {practice.practice_contact}</div><br />
            <p>Thank You. We'll see you soon.</p>
        </div>

    );
}
